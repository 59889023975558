import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import siteconfig from '../../siteconfig.esm'
import locales from '../locales/index.esm'

const labels = locales[process.env.GATSBY_LOCALE]

const Glossary = (props) => {
  const { glossary, siteData } = props.pageContext

  function GlossaryTerms (props) {
    const glossarySections = siteconfig.setup.sections
      ? siteData.contentSections.reduce((acc, i) => {
          const quizids = i.relationships.field_section_quiz.map(q => q.id)
          const quizsection = acc.find(s => s.section.id === i.id)
          const glossaryItems = glossary.filter(g => quizids.includes(g.category.id))
          if (quizsection) {
            quizsection.glossary.push(glossaryItems)
          } else {
            acc.push({
              section: i,
              glossary: glossaryItems
            })
          }
          return acc
        }, [])
      : []

    if (props.main) {
      if (!siteconfig.setup.sections || (siteconfig.setup.sectionGlossary && siteData.basePath !== '/')) {
        // single section page
        const items = siteconfig.setup.sections
          ? glossary.filter(cat => props.section.relationships.field_section_quiz.map(q => q.id).includes(cat.category.id))
          : glossary

        return (<>{items.map(cat => (
          <div key={cat.category.id}>
            <span id={cat.category.id} className="anchor"></span>
            <h2>{ cat.category.field_short_name }</h2>
            <dl>
              {cat.terms.map(term => (
                <div key={term.id}>
                  <dt>{ term.field_glossary_term }</dt>
                  <dd>{ term.field_glossary_meaning }</dd>
                </div>
              ))}
            </dl>
          </div>
        ))}</>)
      } else {
        return (<>{glossarySections.map(section => (
          <div key={section.section.id}>
            <span id={section.section.id} className="anchor" />
            <h2>{ section.section.field_section_title }</h2>
            {section.glossary.map(cat => (
              <div key={cat.category.id}>
                <span id={cat.category.id} className="anchor" />
                <h3>{ cat.category.field_short_name }</h3>
                <dl>
                  {cat.terms.map(term => (
                    <div key={term.id}>
                      <dt>{ term.field_glossary_term }</dt>
                      <dd>{ term.field_glossary_meaning }</dd>
                    </div>
                  ))}
                </dl>
              </div>))}
          </div>
        ))}</>)
      }
    } else {
      if (!siteconfig.setup.sections || (siteconfig.setup.sectionGlossary && siteData.basePath !== '/')) {
        return (
          <ul>{glossary.map(cat => (
              <li key={cat.category.id}><a href={`#${cat.category.id}`} aria-label={'glossary-'+cat.category.field_short_name}><span>{ cat.category.field_short_name }</span></a>
            </li>))}
          </ul>)
      } else {
        return (
          <ul>{glossarySections.map(sec => (
            <li key={sec.section.id}>
              {sec.section.field_section_title}
              <ul>{sec.glossary.map(cat => (
                <li key={cat.category.id}>
                  <a href={`#${cat.category.id}`} aria-label={'glossary-'+cat.category.field_short_name}>
                    <span>{ cat.category.field_short_name }</span>
                  </a>
                </li>))}
              </ul>
            </li>))}
          </ul>)
      }
    }
  }


  return (
    <Layout siteData={siteData} seo={{title: siteData.pageTitles.glossary}}>
      <PageHeader crumbs={props.pageContext.breadcrumb} />
      <Container className="not-front glossary-page sidebar-top">
        <Row>
          <Col md={{ order: 'first', span: 8 }} xs={{ order: 'last', span: 12 }} className="pe-md-5">
            <h1>{labels.glossary}</h1>
            <GlossaryTerms main={true} />
          </Col>
          <Col md={{ order: 'last', span: 4 }} xs={{ order: 'first', span: 12 }} className="sidebar">
            <h2>
              {labels['terms-by-quiz']}
            </h2>
            <GlossaryTerms main={false} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Glossary
